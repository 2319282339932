<template>
    <div class="pa-3">
        <v-card flat :loading="loadingView">

            <v-card-text class="items">
                <v-row class="mb-2">
                    <v-col cols="6" md="9">
                        <v-row class="mb-2">
                            <v-col class="pb-0">
                                <v-text-field :value="supplier" @input="setSupplier" placeholder="Supplier" flat hide-details solo-inverted></v-text-field>
                            </v-col>
                            <v-col class="pb-0">
                                <v-text-field :value="po" @input="setPo" placeholder="P.O. #" flat hide-details solo-inverted></v-text-field>
                            </v-col>
                            <v-col class="pb-0">
                                <v-text-field :value="invoiceNum" @input="setInvoiceNum" placeholder="Invoice #" flat hide-details solo-inverted></v-text-field>
                            </v-col>
                            <v-col class="pb-0">
                                <v-text-field :value="mfrsStyle" @input="setMfrsStyle" placeholder="Mfrs. style" flat hide-details solo-inverted></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mb-2">
                            <v-col class="py-0">
                                <v-text-field :value="style" @input="setStyle" placeholder="Style" flat hide-details solo-inverted></v-text-field>
                            </v-col>
                            <v-col class="py-0">
                                <v-text-field :value="size" @input="setSize" placeholder="Size" flat hide-details solo-inverted></v-text-field>
                            </v-col>
                            <v-col class="py-0">
                                <v-text-field :value="alteration" @input="setAlteration" placeholder="Alteration" flat hide-details solo-inverted></v-text-field>
                            </v-col>
                        </v-row>

                        <v-text-field v-model="barcode" placeholder="Barcode" flat hide-details solo-inverted @keydown.enter="registerItem"></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <div class="text-center text-h2">{{ totalItemsCreated }}</div>
                        <div class="subtitle-1 text-center">Total items created</div>
                    </v-col>
                </v-row>
                <v-data-table :headers="itemHeaders" :items="items" :footer-props="paginationOptions" item-key="id" :options="pagination" :loading="loading">
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="warningDialog" width="500">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to restart this process? All fields will be cleared.

                    <div class="mt-4"><strong>Important</strong>: The items in the table are already saved.</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="warningDialog = false">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="clear">Clear</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-fab-transition>
            <v-btn color="grey darken-4 mb-9" dark absolute bottom right fab @click="warningDialog = true">
                <v-icon>mdi-restart</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        itemHeaders                 : [
            { text: 'Style', value: 'style', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Barcode', value: 'barcode', sortable: true },
            { text: 'Serial #', value: 'id', sortable: true },
            { text: 'Cur. location', value: 'currentLocation', sortable: true },
            { text: 'Date added', value: 'dateCreated', sortable: true },
            { text: 'Altered', value: 'altered', sortable: true },
            { text: 'Supplier', value: 'supplier', sortable: true },
            { text: 'P.O. #', value: 'po', sortable: true },
            { text: 'Invoice #', value: 'invoiceNum', sortable: true },
            { text: 'Mfrs style', value: 'mfrsStyle', sortable: true },
            { text: 'Name', value: 'name', sortable: true }
        ],
        pagination                  : {
            sortBy: ['style']
        },
        paginationOptions           : { itemsPerPageOptions: [ 50, 75, 100, -1 ] },

        loading                     : false,
        loadingView                 : false,
        warningDialog               : false,

        barcode                     : ''
    }),
    computed: {
        ...mapGetters({
            items       : 'inventory/create/items',
            supplier    : 'inventory/create/supplier',
            po          : 'inventory/create/po',
            invoiceNum  : 'inventory/create/invoiceNum',
            style       : 'inventory/create/style',
            size        : 'inventory/create/size',
            alteration  : 'inventory/create/alteration',
            mfrsStyle   : 'inventory/create/mfrsStyle',
        }),
        totalItemsCreated() {
            return this.items.length;
        }
    },
    methods: {
        ...mapActions({
            setSupplier     : 'inventory/create/setSupplier',
            setPo           : 'inventory/create/setPo',
            setInvoiceNum   : 'inventory/create/setInvoiceNum',
            setStyle        : 'inventory/create/setStyle',
            setSize         : 'inventory/create/setSize',
            setAlteration   : 'inventory/create/setAlteration',
            setMfrsStyle    : 'inventory/create/setMfrsStyle',
            createItem      : 'inventory/create/createItem',
            clearAll        : 'inventory/create/clear'
        }),
        async init() {
            try {
                this.loadingView = true;
                // await this.loadProducts();
                this.loadingView = false;
            }
            catch(error) {
                this.loadingView = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        async registerItem() {
            try {
                await this.createItem( this.barcode );
            }
            finally {
                this.barcode = '';
            }
        },
        clear() {
            this.clearAll();
            this.barcode = '';
            this.warningDialog = false;
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>